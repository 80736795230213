<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import router from "@/router";
import { HTTP } from "@/main-source";
import Swal from "sweetalert2";
import { required, email, minLength } from "vuelidate/lib/validators";
import modal from "@/components/modal";
import companyUser from "./company-user";

export default {
  page: {
    meta: [{ name: "Company Details" }],
  },
  components: { Layout, PageHeader, modal, "company-user": companyUser },
  data() {
    return {
      xhrRequest: true,
      data: null,
      invitations: null,
      xhrInvitations: this.isEventPage ? true : false,
      newUserModal: false,
      updateSubUserModal: false,
      subuser: {
        name: null,
        email: null,
        tel: null,
        password:
          this.$route.name == "investor-detail" ? this.makeRandom(10) : null,
      },
      columns: [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Phone",
          field: "phone",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      newInviteColumns: [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Phone",
          field: "phone",
        },
        {
          label: "Status",
          field: "status",
        },
      ],
      invitationsRows: [],
      newInviteRows: [],
      newInviteModal: false,
      acceptedInvitationsCount: 0,
    };
  },
  validations: {
    subuser: {
      name: { required },
      email: { required, email },
      password: { required, minLength: minLength(5) },
    },
    newUsageRight: {
      count: { required },
    },
  },
  created() {
    this.getData();
    if (this.isEventPage) {
      this.getInvitations();
    }
  },
  methods: {
    changeStatus(userId, status) {
      let data = { userId, status: !status };
      HTTP.patch("users/" + data.userId + "/status", data);
    },
    addNew: function () {
      let routeName = null;

      switch (this.$route.name) {
        case "investor-detail":
          routeName = "new-investors";
          break;
        case "company-detail":
          routeName = "new-company";
          break;
        case "event-detail":
          routeName = "new-event";
          break;

        default:
          break;
      }
      router.push({
        name: routeName,
      });
    },
    inviteNew() {
      let selectedInvestors = this.$refs["new-invitations"].selectedRows;
      HTTP.post("events/" + this.$route.params.id + "/invitations", {
        selectedInvestors,
      }).then(() => {
        window.location.reload();
      });
    },
    makeRandom(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    clearSubUserModalData: function () {
      this.subuser = {
        name: null,
        email: null,
        tel: null,
        password:
          this.$route.name == "investor-detail" ? this.makeRandom(10) : null,
      };
    },
    addSubUser: function () {
      this.$v.subuser.$touch();
      if (!this.$v.subuser.$error) {
        HTTP.post("firms/" + this.$route.params.id + "/user", this.subuser)
          .then(() => {
            this.getData();
            this.newUserModal = false;
            this.clearSubUserModalData();
          })
          .catch((e) => {
            if (e.response.status == 409) {
              Swal.fire({
                icon: "warning",
                text: "E-mail must be unique. Try different e-mail adress.",
              });
            }
            if (e.response.status == 412) {
              Swal.fire({
                icon: "warning",
                text: "Please check form",
              });
            }
          });
      }
    },
    updateUserDialog: function (userId) {
      this.subuser = this.data.users.filter((u) => u.id == userId)[0];
      this.subuser.password = null;
      this.updateSubUserModal = true;
    },
    updateSubUser: function () {
      HTTP.put("users/" + this.subuser.id, this.subuser)
        .then(() => {
          Swal.fire({
            icon: "success",
            text: "Successfully Updated.",
          });
          setTimeout(() => {
            window.location.reload();
          }, 1400);
        })
        .catch((e) => {
          console.log(e);
          if (e.response.status == 409) {
            Swal.fire({
              icon: "warning",
              text: "E-mail registered before",
            });
          }
          if (e.response.status == 412) {
            Swal.fire({
              icon: "warning",
              text: "Please check form",
            });
          }
        });
    },
    getData: function () {
      this.xhrRequest = true;
      this.list = [];
      let uri = "firms";

      if (this.$route.name == "event-detail") {
        uri = "events";
      }
      uri += "/" + this.$route.params.id + "?includePassive=true";
      HTTP.get(uri)
        .then((result) => {
          this.data = result.data.rows[0];

          this.xhrRequest = false;
        })
        .catch(() => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Error while getting your data",
            showConfirmButton: false,
            timer: 1500,
          });
          this.xhrRequest = false;
        });
    },
    getInvitations: function () {
      let uri = "events/" + this.$route.params.id + "/invitations";
      HTTP.get(uri)
        .then((result) => {
          this.invitations = result.data.rows;
          this.acceptedInvitationsCount = this.invitations.filter(
            (invitation) => invitation.status == "2"
          ).length;
          this.invitations.forEach((invitation) => {
            this.invitationsRows.push({
              id: invitation.id,
              name: invitation.user.name,
              email: invitation.user.email,
              phone: invitation.user.tel,
              investorId: invitation.user.id,
              status: this.computeInvitationStatus(invitation.status),
            });
          });

          this.xhrInvitations = false;
        })
        .then(() => {
          this.setNewInvitationData();
        })
        .catch((e) => {
          console.log(e);
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Error while getting your data",
            showConfirmButton: false,
            timer: 1500,
          });
          this.xhrInvitations = false;
        });
    },
    computeInvitationStatus: function (status) {
      // 1=sent
      // 2=accepted
      // 3=rejected
      // 4=failed
      let statusText = "";
      switch (status) {
        case 1:
          statusText = "Sent";
          break;
        case 2:
          statusText = "Accepted";
          break;
        case 3:
          statusText = "Maybe";
          break;
        case 4:
          statusText = "Rejected";
          break;
        case 5:
          statusText = "Failed";
          break;
      }
      return statusText;
    },
    setNewInvitationData() {
      let uri = "firms?isInvestor=true";
      HTTP.get(uri)
        .then((result) => {
          if (result.data.rows) {
            let investorIds = this.invitationsRows.map((investor) => {
              return investor.investorId;
            });

            result.data.rows.forEach((row) => {
              row.users.forEach((user) => {
                if (!investorIds.includes(user.id)) {
                  this.newInviteRows.push({
                    id: user.id,
                    name: user.name,
                    fon: row.name,
                    email: user.email,
                    phone: user.tel,
                    status: "Not Invited",
                  });
                }
              });
            });
          }
        })
        .catch(() => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Error while getting investors data",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    sendInvitationAgain(invitationId) {
      HTTP.get("invitations/" + invitationId + "/sendagain")
        .then(() => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Invitation sent again",
            showConfirmButton: false,
            timer: 1000,
          });
        })
        .catch(() => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Getting error while trying to send invitation again.",
            showConfirmButton: false,
            timer: 1000,
          });
        });
    },
    goEdit() {
      if (this.isEventPage) {
        router.push({
          name: "event-edit",
          params: { id: this.$route.params.id },
        });
      } else {
        router.push({
          name: this.data.isInvestor ? "investor-edit" : "company-edit",
          params: { id: this.$route.params.id },
        });
      }
    },
  },
  watch: {
    "$route.name": function () {
      this.getData();
    },
    newUserModal: function () {
      this.clearSubUserModalData();
      this.$v.subuser.$reset();
    },
  },
  computed: {
    pageTitle: function () {
      let pageTitle = null;
      switch (this.$route.name) {
        case "investor-detail":
          pageTitle = "Investor Detail";
          break;
        case "company-detail":
          pageTitle = "Company Detail";
          break;
        case "event-detail":
          pageTitle = "Event Detail";
          break;

        default:
          break;
      }
      return pageTitle;
    },
    isCompanyPage: function () {
      return this.$route.name == "company-detail";
    },
    isInvestorPage: function () {
      return this.$route.name == "investor-detail";
    },
    isEventPage: function () {
      return this.$route.name == "event-detail";
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="pageTitle" />
    <div class="row" :class="{ waiting: xhrRequest }">
      <div class="spinner-border text-success" role="status" v-if="xhrRequest">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="d-flex w-100" v-if="!xhrRequest">
        <div class="col-lg-3">
          <div class="card">
            <div class="row">
              <div class="col-12 p-3 pr-4" v-if="$isAdmin()">
                <button
                  type="button"
                  class="btn btn-info floated-button btn-rounded"
                  @click="goEdit"
                >
                  <i class="mdi mdi-pencil font-size-18"></i>
                </button>
              </div>
            </div>
            <div class="card-body">
              <img
                :src="
                  data.profileImg
                    ? $apiDomain + data.profileImg
                    : require('@/assets/images/no-image.jpg')
                "
                alt="Card image"
                class="card-img-top"
              />
              <h4 class="card-title mt-3">
                <h4 class="card-title text-center">{{ data.name }}</h4>
              </h4>
              <div v-if="isEventPage">
                <div class="row mt-3">
                  <div class="col">
                    <i class="ri-calendar-2-line"></i>
                    {{ new Date(data.date).toLocaleDateString("en") }}
                  </div>
                  <div class="col">
                    <i class="ri-time-line"></i>
                    {{ new Date(data.date).toLocaleTimeString("en") }}
                  </div>
                </div>
                <div class="row text-center mt-4">
                  <div class="col-12" v-if="data.firmId">
                    <b
                      >Relational with <u>{{ data.firm.name }}</u></b
                    >
                  </div>
                </div>
                <div class="row text-center mt-3" v-if="!xhrInvitations">
                  <div class="col-6">
                    Total Invitations
                    <span class="hero-number">
                      {{ invitations ? invitations.length : 0 }}
                    </span>
                  </div>
                  <div class="col-6">
                    Accepted Invitations
                    <span class="hero-number">
                      {{ acceptedInvitationsCount }}
                    </span>
                  </div>
                  <hr />
                  <div class="col-12" v-html="data.desc"></div>
                </div>
              </div>
            </div>
            <!----><!---->
          </div>
        </div>
        <div class="col-lg-9">
          <div class="user-list card" v-if="!isEventPage">
            <!----><!---->
            <div class="card-body">
              <button
                type="button"
                class="btn btn-success floated-button"
                @click="newUserModal = true"
                v-if="$isAdmin()"
              >
                + Add New
              </button>
              <h4 class="card-title">
                <h4 class="card-title">Users</h4>
              </h4>
              <div class="table-responsive mt-3">
                <table
                  class="table table-centered datatable dt-responsive nowrap"
                  style="
                    border-collapse: collapse;
                    border-spacing: 0px;
                    width: 100%;
                  "
                >
                  <thead class="thead-light">
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th style="width: 120px" v-if="$isAdmin()">Status</th>
                      <th style="width: 40px" v-if="$isAdmin()">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(user, index) in data.users" :key="index">
                      <td>{{ user.name }}</td>
                      <td>{{ user.email }}</td>
                      <td>{{ user.tel }}</td>
                      <td class="text-center">
                        <div
                          class="custom-switch switch-primary p-0"
                          v-if="$isAdmin()"
                        >
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            :id="user.id"
                            :checked="user.status"
                            @change="changeStatus(user.id, user.status)"
                          />
                          <label class="custom-control-label" :for="user.id">
                            <!-- {{
                            user.status ? "Active" : "Passive"
                          }} -->
                          </label>
                        </div>
                      </td>
                      <td>
                        <!-- @click="updateSubUserStatus(user.id, user.status)" -->
                        <a
                          href="javascript:void(0);"
                          title="Edit"
                          class="mr-3 text-primary"
                          @click="updateUserDialog(user.id)"
                          v-if="$isAdmin()"
                        >
                          <i class="mdi mdi-pencil font-size-18"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!----><!---->
          </div>

          <!-- Event Detail Invitations Page -->
          <div class="event-invitations card" v-if="isEventPage">
            <div class="card-body">
              <button
                type="button"
                class="btn btn-success floated-button"
                @click="newInviteModal = true"
              >
                + Invite
              </button>
              <h4 class="card-title">Invitations</h4>
              <vue-good-table
                :columns="columns"
                :rows="invitationsRows"
                ref="invitations"
                :select-options="{
                  enabled: false,
                  selectOnCheckboxOnly: false,
                  selectionInfoClass: 'custom-class',
                  selectionText: 'rows selected',
                  clearSelectionText: 'clear',
                  disableSelectInfo: true,
                  selectAllByGroup: true,
                }"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'action'">
                    <button
                      class="btn btn-light"
                      v-if="props.row.status != 'Accepted'"
                      @click="sendInvitationAgain(props.row.id)"
                    >
                      <i class="ri-send-plane-fill"></i>
                      Send Again
                    </button>
                  </span>
                </template>
              </vue-good-table>
            </div>
          </div>
          <!-- Event Detail Invitations Page End -->
          <company-user v-if="!$isAdmin()"></company-user>
        </div>
      </div>

      <!-- New User Modal -->
      <modal v-if="updateSubUserModal" @close="updateSubUserModal = false">
        <div slot="title">Update User</div>
        <div slot="body">
          <div class="form-group mt-2">
            <label>Name</label>
            <input
              type="text"
              class="form-control"
              placeholder="Name"
              v-model="subuser.name"
              autocomplete="new-password"
              @blur="$v.subuser.name.$touch()"
              :class="{
                'is-invalid': $v.subuser.name.$error,
              }"
            />
            <div class="invalid-feedback">Required.</div>
          </div>
          <div class="form-group mt-2">
            <label>Email</label>
            <input
              type="text"
              class="form-control"
              placeholder="Email"
              v-model="subuser.email"
              autocomplete="new-password"
              @blur="$v.subuser.email.$touch()"
              :class="{
                'is-invalid': $v.subuser.email.$error,
              }"
            />
            <div class="invalid-feedback">Required</div>
          </div>
          <div class="form-group mt-2">
            <label>Phone</label>
            <the-mask
              mask="0 (###) ### ## ##"
              v-model="subuser.tel"
              class="form-control"
              type="phone"
              placeholder="Phone"
            ></the-mask>
            <div class="invalid-feedback">Required</div>
          </div>
          <div class="form-group mt-2" v-if="isCompanyPage">
            <label>Password</label>
            <input
              type="password"
              class="form-control"
              placeholder="Password"
              v-model="subuser.password"
              autocomplete="new-password"
              @blur="$v.subuser.password.$touch()"
              :class="{
                'is-invalid': $v.subuser.password.$error,
              }"
            />
            <div class="invalid-feedback">Required</div>
          </div>
        </div>
        <div slot="footer">
          <button
            type="button"
            class="
              btn btn-md btn-danger
              waves-effect waves-light
              pr-4
              pl-4
              mr-3
            "
            @click="updateSubUserModal = false"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-md btn-primary waves-effect waves-light pr-4 pl-4"
            @click="updateSubUser"
          >
            Update
          </button>
        </div>
      </modal>
      <!-- Update User Modal  END-->
      <!--New User Modal -->
      <modal v-if="newUserModal" @close="newUserModal = false">
        <div slot="title">New User</div>
        <div slot="body">
          <div class="form-group mt-2">
            <label>Name</label>
            <input
              type="text"
              class="form-control"
              placeholder="Name"
              v-model="subuser.name"
              autocomplete="new-password"
              @blur="$v.subuser.name.$touch()"
              :class="{
                'is-invalid': $v.subuser.name.$error,
              }"
            />
            <div class="invalid-feedback">Required.</div>
          </div>
          <div class="form-group mt-2">
            <label>Email</label>
            <input
              type="text"
              class="form-control"
              placeholder="Email"
              v-model="subuser.email"
              autocomplete="new-password"
              @blur="$v.subuser.email.$touch()"
              :class="{
                'is-invalid': $v.subuser.email.$error,
              }"
            />
            <div class="invalid-feedback">Required</div>
          </div>
          <div class="form-group mt-2">
            <label>Phone</label>
            <the-mask
              mask="0 (###) ### ## ##"
              v-model="subuser.tel"
              class="form-control"
              type="phone"
              placeholder="Phone"
            ></the-mask>
            <div class="invalid-feedback">Required</div>
          </div>
          <div class="form-group mt-2" v-if="isCompanyPage">
            <label>Password</label>
            <input
              type="password"
              class="form-control"
              placeholder="Password"
              v-model="subuser.password"
              autocomplete="new-password"
              @blur="$v.subuser.password.$touch()"
              :class="{
                'is-invalid': $v.subuser.password.$error,
              }"
            />
            <div class="invalid-feedback">Required</div>
          </div>
        </div>
        <div slot="footer">
          <button
            type="button"
            class="
              btn btn-md btn-danger
              waves-effect waves-light
              pr-4
              pl-4
              mr-3
            "
            @click="newUserModal = false"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-md btn-primary waves-effect waves-light pr-4 pl-4"
            @click="addSubUser"
          >
            + Add
          </button>
        </div>
      </modal>
      <!--Update User Modal -->
      <!-- New Invite Modal -->
      <modal
        v-if="newInviteModal"
        @close="newInviteModal = false"
        class="bigger-modal"
      >
        <div slot="title">New Invite</div>
        <div slot="body">
          <vue-good-table
            :columns="newInviteColumns"
            :rows="newInviteRows"
            ref="new-invitations"
            :select-options="{
              enabled: true,
              selectOnCheckboxOnly: false,
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true,
              selectAllByGroup: true,
            }"
          >
          </vue-good-table>
        </div>
        <div slot="footer">
          <button
            type="button"
            class="
              btn btn-md btn-danger
              waves-effect waves-light
              pr-4
              pl-4
              mr-3
            "
            @click="newInviteModal = false"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-md btn-primary waves-effect waves-light pr-4 pl-4"
            @click="inviteNew"
          >
            + Invite
          </button>
        </div>
      </modal>
      <!-- New Invite Modal -->
    </div>
  </Layout>
</template>