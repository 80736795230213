<template>
  <div class="col-lg-12 p-0">
    <div class="card">
      <div class="card-body">
        <span class="h6">Events</span>
        <div v-for="event in events" :key="event.id">
          <!--  -->
          <b-card
            no-body
            class="cursor-pointer"
            @click="goEventSurveys(event.id)"
          >
            <b-row no-gutters class="align-items-center">
              <b-col md="4">
                <b-card-img
                  :img-src="
                    event.profileImg
                      ? $apiDomain + event.profileImg
                      : require('@/assets/images/no-image.jpg')
                  "
                  class="rounded-0"
                ></b-card-img>
              </b-col>
              <b-col>
                <b-card-body :title="event.name">
                  <div class="d-flex w-100 p-0">
                    <div class="col-12 p-0">
                      <i class="ri-calendar-2-line"></i>
                      {{ new Date(event.date).toLocaleDateString("en") }}
                    </div>
                    <div class="col-12 p-0">
                      <i class="ri-time-line"></i>
                      {{ new Date(event.date).toLocaleTimeString("en") }}
                    </div>
                  </div>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
          <!--  -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
import router from "@/router";

export default {
  name: "CompanyUser",
  data() {
    return {
      events: null,
      surveys: null,
    };
  },
  created() {
    this.getEvents();
  },
  methods: {
    getEvents() {
      HTTP.get("firms/" + this.$route.params.id + "/events").then(
        (response) => {
          console.log(
            "🚀 ~ file: company-user.vue ~ line 39 ~ getEvents ~ response",
            response
          );
          this.events = response.data.events;
        }
      );
    },
    goEventSurveys(eventId) {
      router.push({
        name: "event-surveys",
        params: {
          id: this.$route.params.id,
          eventId: eventId,
        },
      });
    },
  },
};
</script>